import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {UsuarioCertificadoModalSenhaService} from './usuario-certificado-modal-senha.service';
import { EnumTipoCertificadoEnvioPortalUnico } from '../../enums/enum-parametro-configuracao';

@Component({
    selector: 'usuario-certificado',
    templateUrl: './usuario-certificado-modal-senha.component.html',
    styleUrls: ['./usuario-certificado-modal-senha.component.scss'],
})
export class UsuarioCertificadoModalSenhaComponent implements OnInit {
    public senha = new FormControl('', [
        Validators.required
    ]);
    public esconderSenha: boolean = true;
    public tipoCertificadoPadrao = EnumTipoCertificadoEnvioPortalUnico.Padrao;

    public opcaoCertificadoSelecionada = new FormControl(this.data.ocultarTipoCertificado ? null : EnumTipoCertificadoEnvioPortalUnico.Usuario, [
        Validators.required
    ]);

    public opcoesCertificado = [
        {
            id: EnumTipoCertificadoEnvioPortalUnico.Usuario,
            descricao: 'Usuário'
        },
        {
            id: EnumTipoCertificadoEnvioPortalUnico.Padrao,
            descricao: 'Padrão'
        }
    ]

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { logar: boolean, solicitarSenha: boolean, ocultarTipoCertificado: boolean },
        @Inject(UsuarioCertificadoModalSenhaService) public service: UsuarioCertificadoModalSenhaService,
    ) {
    }

    ngOnInit(): void {
    }
}
