import { EnumTipoCertificadoEnvioPortalUnico } from "../../enums/enum-parametro-configuracao";

export class UsuarioCertificadoStateModel {
  existeUsuario?: boolean = false;
  existePadrao?: boolean;
  enviando?: boolean;
  progress?: number;
  nomeCertificado?: string;
  dataVencimento?: Date;
  cpf?: string;
  modalSenha: string;
  modalSenhaConfirmado: boolean;
  modalRemover: string;
  tipoCertificadoSelecionado?: EnumTipoCertificadoEnvioPortalUnico;
}
