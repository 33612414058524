<mat-dialog-content class="mat-typography">
  <h3>
    Certificado Digital
  </h3>

  <div style="display: flex;" *ngIf="!data.ocultarTipoCertificado">
    <mat-radio-group ngDefaultControl [formControl]="opcaoCertificadoSelecionada" style="padding-right: 4em;">
      <mat-radio-button *ngFor="let opcao of opcoesCertificado" [value]="opcao.id">
        {{opcao.descricao}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-form-field *ngIf="data.solicitarSenha && opcaoCertificadoSelecionada.value != tipoCertificadoPadrao">
    <mat-label>Senha</mat-label>
    <input matInput [formControl]="senha" placeholder="senha" [type]="esconderSenha ? 'password' : 'text'" />
    <mat-icon matSuffix (click)="esconderSenha = !esconderSenha">{{esconderSenha ? 'visibility_off' :
      'visibility'}}</mat-icon>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="true" (click)="service.fechar()">
    Cancelar
  </button>
  <button type="submit" (click)="service.validar(senha.value, data.logar, opcaoCertificadoSelecionada.value)"
    mat-flat-button color="primary"
    [disabled]="data.solicitarSenha && opcaoCertificadoSelecionada.value != tipoCertificadoPadrao && (senha.invalid || (!senha.dirty || !senha.touched))">
    Confirmar
  </button>
</mat-dialog-actions>